import { ref, reactive } from 'vue';

interface Toaster {
    id: number;
    message: string;
    level: 'info' | 'success' | 'error';
    closable: boolean;
    hasCallback: boolean;
    callback: Function;
    callbackText: string;
    timeout: number;
}

const toasters = ref<Toaster[]>([]);

function addToaster(message, options) {
    toasters.value.push({
        id: Math.random(),
        message,
        level: options.level || 'info',
        closable: options.closable || !!options.callback,
        hasCallback: !!options.callback,
        callbackText: options.callbackText,
        callback: options.callback || function() {},
        timeout: options.timeout !== undefined ? options.timeout : 3000,
    });
}

function removeToaster(id: number) {
    toasters.value = toasters.value.filter(toaster => toaster.id !== id);
}

const toasterOptions = reactive({
    position: 'top-right',
    padding: {
        x: 8,
        y: 69
    },
    timeout: 3000,
});

function setToasters(options) {
    toasterOptions.position = (options && options.position) || 'top-right';
    toasterOptions.padding = (options && options.padding) || { x: 8, y: 69 };
    toasterOptions.timeout = (options && options.timeout) || 3000;
}

export default function useToasters() {
    return {
        toasters,
        addToaster,
        removeToaster,
        setToasters,
        toasterOptions,
    };
}

interface Options {
    closable?: boolean;
    callback?: Function;
    callbackText?: string;
    timeout?: number;
}

function success(message: string, options?: Options) {
    addToaster(message, { level: 'success', ...options });
}

function info(message: string, options?: Options) {
    addToaster(message, { level: 'info', ...options });
}

function error(message: string, options?: Options) {
    addToaster(message, { level: 'error', ...options });
}

const toaster = { success, info, error };
export { toaster };
