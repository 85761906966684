import { Filter } from '@/dashboard/common/types/filterInterface';

export enum PromptType {
    SUMMARIZE = 'SUMMARIZE',
    PAIN_POINTS = 'PAIN_POINTS',
    LOVE_POINTS = 'LOVE_POINTS',
}

export enum MetricType {
    NPS = 'nps',
    CES = 'ces',
    CSAT = 'csat',
}

export interface Topic {
    id: number,
    type: string,
}

export interface AiResult {
    id: number,
    dimensionId: string,
    name: string,
    creatorId: number,
    creatorName: string,
    promptType: PromptType,
    filters: {
        [uuid: string]: {
            dashboardFilters: Array<Filter>,
            aiFilters: Array<Filter>
        }
    },
    recordCounts: { [uuid: string]: number },
    result: string,
    createdAt: string,
    status: AiResultStatus,
    isReadByCreator: boolean,
}

export enum AiResultStatus {
    GENERATING = 'generating',
    FINISHED = 'finished',
    REGENERATED = 'regenerated',
    FAILED = 'failed',
}
