import DimensionTypeEnum from '@/domain/dimensionTypeEnum.js';
import { isUrlColumnDataValid } from '@/utils/urlColumnValidation';

function isNumber(data: string): boolean {
    return !Number.isNaN(+data);
}

export default function(columnData: Array<string>, columnType: DimensionTypeEnum ): boolean {
    switch (columnType) {
    case DimensionTypeEnum.URL:
        return isUrlColumnDataValid(columnData);
    case DimensionTypeEnum.DATE:
        return columnData.every(data => !isNaN(Date.parse(data)));
    case DimensionTypeEnum.NUMBER:
        return columnData.every(data => isNumber(data));
    case DimensionTypeEnum.NPS:
        return columnData.every(data => isNumber(data) && +data >= 0 && +data <= 10);
    case DimensionTypeEnum.CSAT:
    case DimensionTypeEnum.CES_5_SCALE:
        return columnData.every(data => isNumber(data) && +data >= 1 && +data <= 5);
    case DimensionTypeEnum.CES_7_SCALE:
        return columnData.every(data => isNumber(data) && +data >= 1 && +data <= 7);
    case DimensionTypeEnum.ID:
        return columnData.length === new Set(columnData).size;
    case DimensionTypeEnum.VERBATIM:
    case DimensionTypeEnum.CATEGORY:
    case DimensionTypeEnum.DONT_USE:
    case DimensionTypeEnum.NON_UNIQUE_ID:
    default:
        return true;
    }
}
