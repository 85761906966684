import { api } from '@/api';
import { AiResult, PromptType } from '../utils/aiTypes';
import { Filter } from '@/dashboard/common/types/filterInterface';

export async function getAiResults(datasetId: string, signal): Promise<Array<AiResult>> {
    const { data } = await api.get(`/aiResults?datasetId=${datasetId}`, {
        signal
    });

    return data;
}

export async function getAiResult(id: number): Promise<AiResult> {
    const { data } = await api.get(`/aiResults/${id}`);

    return data;
}

export interface GenerateAiResultPayload {
    datasetId: string,
    dimensionId: string,
    promptType: PromptType,
    filters: {
        dashboardFilters: Array<Filter>,
        aiFilters: Array<Filter>
    }
}

export async function generateAiResult(payload: GenerateAiResultPayload): Promise<number> {
    const { data } = await api.post('/aiResults', {
        ...payload,
        filters: [payload.filters]
    });

    return data;
}

export interface RegenerateAiResultPayload {
    id: number,
    name: string,
    promptType: PromptType,
    filters: {
        dashboardFilters: Array<Filter>,
        aiFilters: Array<Filter>
    }
}

export async function regenerateAiResult(payload: RegenerateAiResultPayload): Promise<number> {
    const { data } = await api.post(`/aiResults/${payload.id}/regenerate`, {
        name: payload.name,
        promptType: payload.promptType,
        filters: [payload.filters]
    });

    return data;
}

interface UpdateAiResultPayload {
    aiResultId,
    name,
    isReadByCreator,
}

export async function updateAiResult(payload: UpdateAiResultPayload): Promise<any> {
    const { data } = await api.put(`/aiResults/${payload.aiResultId}`, {
        name: payload.name,
        isReadByCreator: payload.isReadByCreator
    });

    return data;
}

export async function exportAiResult(id): Promise<any> {
    const { data } = await api.post(`/aiResults/${id}/export`, null, { responseType: 'blob' });

    return data;
}

export async function deleteAiResult(id): Promise<void> {
    await api.delete(`/aiResults/${id}`);
}
