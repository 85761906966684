<template>
    <div>
        <label
            v-if="label || $slots.label"
            :style="{color: textColor}"
            :for="id"
        >
            <slot name="label">
                {{ label }}
            </slot>
        </label>
        <div v-if="$slots.tags">
            <slot name="tags" />
        </div>
        <div
            class="input-container"
            :class="{ 'error': !!errorMessage, 'icon': !!$slots.icon , 'border': type !== 'no-border', 'disabled' : disabled }"
            :style="{
                ...style,
                border: 'solid 1px ' + borderColor,
            }"
        >
            <div
                v-if="$slots.startIcon"
                class="input-icon"
            >
                <slot name="startIcon" />
            </div>
            <input
                v-if="!$slots.result"
                :id="id"
                ref="input"
                :data-test-id="dataTestId"
                :value="value"
                :type="type"
                :placeholder="placeholder"
                :disabled="disabled"
                :max="max"
                :min="min"
                :maxlength="maxLength"
                :style="style"
                :autocomplete="autocomplete"
                :class="{ 'disabled' : disabled }"
                v-bind="$attrs"
                @input="$emit('input', $event.target.value)"
                @change="$emit('change', $event.target.value)"
                @mouseover="isHovered = true"
                @mouseleave="isHovered = false"
                @focus="isActive = true"
                @focusout="isActive = false"
                v-on="listeners"
            >
            <div
                v-if="$slots.endIcon"
                class="input-icon pr-2"
            >
                <slot name="endIcon" />
            </div>
            <div
                v-if="type === 'number'"
                class="buttons"
                :class="{ 'disabled': disabled }"
            >
                <icon
                    icon="chevron-up"
                    @click="increment"
                />
                <icon
                    icon="chevron-down"
                    @click="decrement"
                />
            </div>
        </div>
        <div
            v-if="$slots.optional"
            class="optional"
        >
            <slot name="optional" />
        </div>
        <span
            v-if="$slots.errorMessage || errorMessage"
            class="errorMessage"
            :data-test-id="dataTestId ? `${dataTestId}-error` : null"
        >
            <slot name="errorMessage">
                {{ errorMessage }}
            </slot>
        </span>
    </div>
</template>


<script lang="ts">
import { ref, defineComponent, computed } from 'vue';
import Icon from '@/components/ui/Icon.vue';
import useUserStore from '@/user/store';

export default defineComponent({
    components: { Icon },
    model: {
        prop: 'value',
        event: 'input'
    },
    props:  {
        label: { type: String, default: null },
        placeholder: { type: String, default: null },
        errorMessage: { type: String, default: null },
        isOptional: { type: Boolean, default: false },
        isOptionalText: { type: String, default: null },
        type: { type: String, default: 'text' },
        max: { type: Number, default: null },
        maxLength: { type: Number, default: null },
        min: { type: Number, default: null },
        disabled: { type: Boolean, default: false },
        value: { type: [String,Number], default: null },
        textColor: { type: String, default: null },
        backgroundColor: { type: String, default: null },
        primaryColor: { type: String, default: null },
        dataTestId: { type: String, default: null },
        id: { type: String, default: () => 'input-' + Math.random() },
        autocomplete: { type: String, default: 'off' }
    },
    setup(props, context) {
        const { state } = useUserStore();

        const input = ref();
        const isHovered = ref(false);
        const isActive = ref(false);
        const listeners = computed(() => {
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            const { input, change, ...listeners } = context.listeners;
            return listeners;
        });

        const primaryColor = computed(() => props.primaryColor || state.primaryColor);
        const backgroundColor = computed(() => props.backgroundColor || '#FFFFFF');
        const textColor = computed(() => props.textColor || '#282828');

        const borderColor = computed(() => {
            if (isActive.value) return primaryColor.value;
            else if (isHovered.value) return textColor.value + '99';
            else if (props.errorMessage) return '#f06060';

            return textColor.value + '4d';
        });

        const style = computed(() => ({
            background: backgroundColor.value,
            color: textColor.value
        }));

        function focus() {
            input.value.focus();
        }

        function increment() {
            if (
                props.type !== 'number'
                || props.disabled
                || (props.max !== null && +props.value >= props.max)
            ) return;

            context.emit('input', +props.value + 1);
            context.emit('change', +props.value + 1);
        }

        function decrement() {
            if (
                props.type !== 'number'
                || props.disabled
                || (props.min !== null && +props.value <= props.min)
            ) return;

            context.emit('input', +props.value - 1);
            context.emit('change', +props.value - 1);
        }

        return {
            input, isHovered, isActive, listeners, focus, borderColor, style, increment, decrement,
        };
    }
});
</script>
<style scoped>
    label {
        display: block;
        width: 100%;
        margin-bottom: 8px;
        padding-left: 8px;
        color: #282828;
        font-size: 14px;
        line-height: 16px
    }
    .input-container {
        align-items: center;
        display: flex;
        position: relative;
        max-width: 100%;
        border-radius: 0.25rem;
        border: 1px solid #838da5;
        color: #282828;
    }
    .input-icon {
        color: #838da5;
        margin-left: 6px;
    }
    input {
        flex-grow: 1;
        border: 0;
        outline: none;
        height: 26px;
        margin: 2px 6px;
        padding: 0;
        font-size: 14px;
        line-height: 16px;
        box-sizing: border-box;
        width: 100%;
    }
    .optional {
        color: #838da5;
        text-align: right;
        font-size: 14px;
    }
    .errorMessage {
        color: #f06060;
        font-size: 14px;
    }
    .disabled {
        opacity: 0.75;
        cursor: not-allowed;
        outline: 2px solid transparent;
        outline-offset: 2px;
    }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.buttons {
    color: #838da5;
    background: #d8dce8;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-self: stretch;
    padding: 0 2px;
    border-radius: 0 0.15rem 0.15rem 0;
    user-select: none;
}

.buttons svg {
    cursor: pointer;
    padding: 2px;
    margin: -2px;
}

.buttons.disabled svg {
    cursor: not-allowed;
}
</style>
